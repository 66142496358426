import { color as d3_color, hsl } from 'd3-color';
import { interpolateLab, interpolateRgb } from 'd3-interpolate';
import { nanoid } from 'nanoid';

import { ciede2000, contrast, isSameColor, luminosity } from '@grid-is/color-utils';

import { getFontStack } from '@/utils/fontstack';

import { DEFAULT_CHART_FONT } from './ChartTheme';

/*
** Aproximations of grays used in the product are:
** fossil   - blend(0.515)
** wise owl - blend(0.85)
** concrete - blend(0.84)
** ash      - blend(0.39)
*/

const WHITE = '#fff'; // white
const BLACK = '#282b3e'; // squid-ink
const HIGHLIGHT = '#99a8ff'; // scabiosa;
const BLUE = '#0025ff'; // aster
const PURPLE = '#5900ff'; // grid-purple
const LIGHTBLUE = '#bac5ff'; // scabiosa + White

// GRID vanilla theme
export const baseTheme = {
  backgroundColor: WHITE,
  backgroundColorOverride: '',
  accentColor: BLACK,
  titleFont: 'Poppins',
  bodyFont: 'Nunito',
  chartFont: DEFAULT_CHART_FONT,
};

function asHSL (c) {
  const cHSL = hsl(c);
  let s = Math.round(cHSL.s * 100);
  if (!isFinite(s)) {
    s = 0;
  }
  return `${isNaN(cHSL.h) ? 0 : Math.round(cHSL.h)},${s}%,${Math.round(cHSL.l * 100)}%`;
}

function getContrast (a, b) {
  const lumA = luminosity(a);
  const lumB = luminosity(b);
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
}

function hasMinContrast (a, b) {
  const lumBg = luminosity(a);
  const lumSh = luminosity(b);
  return !(lumBg > 0.5 === lumSh > 0.5);
}

function tint (c, amount = 0.3, darker = true) {
  const blender = interpolateRgb(c, darker ? '#333' : '#fff');
  return hsl(blender(amount));
}

function textColorFor (bg) {
  return luminosity(bg) <= 0.5 ? WHITE : BLACK;
}

function borderColor (desiredColor, outerBg, innerBg) {
  const minContrast = hasMinContrast(outerBg, innerBg);
  return minContrast ? 'transparent' : desiredColor;
}

function hoverFade (color, amount = 0.1) {
  return interpolateRgb(color, contrast(color, BLACK, WHITE))(amount);
}

function opaque (color, amount = 1) {
  const c = d3_color(color);
  c.opacity = amount;
  return c;
}

export default class Theme {
  /**
   * @param {object} [opts={}] The visual properties of the theme
   * @param {string} [opts.backgroundColor] The background color
   * @param {string} [opts.backgroundColorOverride] The background color
   * @param {string} [opts.accentColor] The accent color
   * @param {string} [opts.bodyFont] The body font
   * @param {string} [opts.titleFont] The title font
   * @param {string} [opts.chartFont] The chart font
   */
  constructor ({ backgroundColor, accentColor, bodyFont, titleFont, chartFont, backgroundColorOverride } = {}) {
    /** @type{string} */
    this.id = nanoid();

    /** @type{string} */
    this.bodyFont = bodyFont || '';
    /** @type{string} */
    this.titleFont = titleFont || '';
    /** @type{string} */
    this.chartFont = chartFont || '';
    /** @type{string} */
    this.baseFont = baseTheme.bodyFont;
    /** @type{string} */
    this.bodyFontStack = getFontStack(bodyFont || baseTheme.bodyFont);
    /** @type{string} */
    this.titleFontStack = getFontStack(titleFont || baseTheme.titleFont);
    /** @type{string} */
    this.chartFontStack = getFontStack(chartFont || baseTheme.chartFont);
    /** @type{string} */
    this.baseFontStack = getFontStack(this.baseFont);

    // colors
    /** @type{string} */
    this.backgroundColorOverride = backgroundColorOverride || '';
    this.backgroundColor = backgroundColor || '';
    const _backgroundColor = backgroundColor || baseTheme.backgroundColor;
    /** @type{string} */
    this.background = backgroundColorOverride || _backgroundColor;

    /** @type{string} */
    this.accentColor = accentColor || '';
    const _accentColor = accentColor || baseTheme.accentColor;

    const _color = backgroundColor ? textColorFor(backgroundColor) : BLACK;
    /** @type{string} */
    this.color = _color;

    const isDark = isSameColor(_color, WHITE);
    const isTransparent = this.background === 'transparent';

    const transparentBlend = num => {
      return opaque(this.color, 1 - num);
    };

    const blend = isTransparent ? transparentBlend : interpolateLab(_color, _backgroundColor);
    const blendAccent = isTransparent ? transparentBlend : interpolateLab(_accentColor, _backgroundColor);
    const unsetColors = !this.backgroundColor && !this.accentColor;

    const color50 = blend(0.5);
    const defaultBorderColor = blend(0.83);
    const hslColor = hsl(_backgroundColor);
    const hasBackgroundHue = !isNaN(hslColor.s) && hslColor.s > 0.01;
    const isBackgroundWhite = isSameColor(_backgroundColor, WHITE);

    // determine link color
    let linkHoverColor;
    let linkColor;
    let linkDecoration = 'none';
    // When we have an accent color that contrasts background, we'll use that
    // as the link color. We're a going by WCAG 2.1 minimum allowed contrast
    // ratio (3:1) against background here (using 5:1 elsewhere).
    if (accentColor && getContrast(_backgroundColor, _accentColor) >= 3) {
      linkColor = _accentColor;
      linkHoverColor = tint(linkColor, 0.3, isDark);
      // If the link color is not visually distinct enough from text, an
      // underline gets added to the links as well.
      linkDecoration = ciede2000(_color, linkColor) > 28
        ? 'none'
        : 'underline';
    }
    // Lacking a usable accent color: If the background is white (or unset), we
    // use GRID's default purple color.
    else if (isBackgroundWhite) {
      linkColor = PURPLE;
      linkHoverColor = tint(linkColor, 0.3, isDark);
    }
    // Having no better options, we set the links to the the text color and
    // underline them.
    else {
      linkColor = _color;
      linkHoverColor = blend(0.3);
      linkDecoration = 'underline';
    }
    // editor specific colors
    const editorUiBg = blend(isDark ? 0.86 : 0.95);
    const editorUiFg = blend(0.3);
    const editorUiDisabled = blend(isDark ? 0.96 : 0.98);
    const editorFocus = hasBackgroundHue ? color50 : HIGHLIGHT;

    this.editorVars = {
      // common between editor and theme
      '--color-95': blend(isDark ? 0.86 : 0.95),
      '--color-90': blend(isDark ? 0.80 : 0.9),
      '--color-80': blend(isDark ? 0.77 : 0.8),
      '--color-70': blend(isDark ? 0.68 : 0.7),
      '--color-60': blend(0.6),
      '--color-50': color50,
      '--color-40': blend(0.4),
      '--color-30': blend(0.3),

      // editor specific
      '--editor-focus-color': editorFocus,
      '--editor-focus-text-color': textColorFor(editorFocus),
      '--editor-fg-purple': isBackgroundWhite ? PURPLE : blend(0.3),
      '--editor-fg-purple-hover': isBackgroundWhite ? PURPLE : blend(0.1),
      '--editor-bg': editorUiBg,
      '--editor-fg': editorUiFg,
      '--editor-disabled': editorUiDisabled,
    };

    this.vars = {
      '--body-font': this.bodyFontStack,
      '--title-font': this.titleFontStack,
      '--chart-font': this.chartFontStack,
      '--controls-font': this.baseFontStack,
      '--label-font': this.bodyFontStack,

      // general colors
      '--background-color': this.background,
      '--background-color-hsl': asHSL(this.background),
      '--accent-color': _accentColor,
      '--accent-color-hover': hoverFade(_accentColor),
      '--text-color': _color,
      '--text-color-hsl': asHSL(_color),
      '--focus-color': hasBackgroundHue ? _color : contrast(_backgroundColor, BLUE, LIGHTBLUE),
      '--selection-color': hasBackgroundHue ? hoverFade(_backgroundColor, 0.15) : tint(HIGHLIGHT, 0.65, isDark),
      '--ruler-color': isBackgroundWhite ? blend(0.84) : blend(0.7),
      '--box-shadow': 'rgba(0,0,0,.12)',

      '--disabled-background': opaque(_color, 0.04),
      '--disabled-text': opaque(_color, 0.45),
      '--disabled-border': opaque(_color, 0.02),

      // input boxes (input | dropdown | unchecked-checkmarks)
      '--input-background': WHITE,
      '--input-background-hover': hoverFade(WHITE),
      '--input-text': BLACK,
      '--input-placeholder': interpolateRgb(BLACK, WHITE)(0.5),
      '--input-border': borderColor(defaultBorderColor, _backgroundColor, WHITE),
      '--input-border-hover': borderColor(blend(0.3), _backgroundColor, WHITE),

      // buttons (button)
      '--button-text': textColorFor(_accentColor),
      '--button-border':  borderColor(defaultBorderColor, _backgroundColor, _accentColor),

      // checked (checked-checkmarks)
      '--checked-background': unsetColors ? BLACK : _accentColor,
      '--checked-background-hover': hoverFade(unsetColors ? BLACK : _accentColor),
      '--checked-text': unsetColors ? WHITE : textColorFor(_accentColor),
      '--checked-text-hover': unsetColors ? WHITE : textColorFor(_accentColor),
      '--checked-border': unsetColors
        ? borderColor(defaultBorderColor, WHITE, BLACK)
        : borderColor(defaultBorderColor, _backgroundColor, _accentColor),

      // slider
      '--slider-track': blend(0.8),
      '--slider-track-hover': blend(0.7),
      '--slider-track-active': hasMinContrast(_backgroundColor, _accentColor) ? blendAccent(0.5) : color50,
      '--slider-thumb': _accentColor,
      // XXX: maybe get rid of this and use --background in the CSS
      '--slider-thumb-mask': this.background,
      '--slider-border': borderColor(blend(0.7), _backgroundColor, _accentColor),
      '--slider-tick': color50,
      '--slider-tick0': blend(0.4),

      // table
      '--table-alt': blend(0.97),
      '--table-line': blend(0.93),

      // helper ui
      '--helper-background': interpolateRgb(BLACK, WHITE)(0.95),
      '--helper-border': interpolateRgb(BLACK, WHITE)(0.82),
      '--helper-text': BLACK,
      '--helper-background-hover': hasMinContrast(WHITE, _accentColor) ? _accentColor : interpolateRgb(BLACK, WHITE)(0.7),
      '--helper-text-hover': hasMinContrast(WHITE, _accentColor) ? textColorFor(_accentColor) : BLACK,
      '--helper-active-background': hasMinContrast(WHITE, _accentColor) ? _accentColor : BLACK,
      '--helper-active-text': hasMinContrast(WHITE, _accentColor) ? textColorFor(_accentColor) : WHITE,
      '--helper-active-background-hover': hasMinContrast(WHITE, _accentColor) ? interpolateRgb(_accentColor, WHITE)(0.3) : interpolateRgb(BLACK, WHITE)(0.3),
      '--helper-active-text-hover': hasMinContrast(WHITE, _accentColor) ? textColorFor(_accentColor) : WHITE,

      // links
      '--link-color': linkColor,
      '--link-hover-color': linkHoverColor,
      '--link-decoration': linkDecoration,

      // datepicker
      '--dp-background': WHITE,
      '--dp-border': borderColor(defaultBorderColor, _backgroundColor, WHITE),
      '--dp-text': BLACK,
      '--dp-header-border': interpolateRgb(BLACK, WHITE)(0.8),
      '--dp-hover': '#efe5ff', // sweet-rocket
      '--dp-selected': HIGHLIGHT, // scabiosa
      '--dp-disabled-text': interpolateRgb(BLACK, WHITE)(0.7),
      '--dp-weekdays': interpolateRgb(BLACK, WHITE)(0.5),
      '--dp-overflow-date': interpolateRgb(BLACK, WHITE)(0.35),

      ...this.editorVars,
    };

    Object.freeze(this.vars);
    Object.freeze(this);
  }

  get isDefault () {
    return (
      this.backgroundColor === baseTheme.backgroundColor &&
      this.accentColor === baseTheme.accentColor &&
      this.titleFont === baseTheme.titleFont &&
      this.bodyFont === baseTheme.bodyFont &&
      this.chartFont === baseTheme.chartFont
    );
  }

  textColorFor (c) {
    return textColorFor(this.resolveColor(c));
  }

  linkColorFor (c) {
    return contrast(c, BLUE, LIGHTBLUE);
  }

  borderColor (shapeFillColor) {
    const fillColor = this.resolveColor(shapeFillColor);
    const lumBg = luminosity(this.background);
    const lumSh = luminosity(fillColor);

    const needBorder = (lumBg > 0.5 === lumSh > 0.5);
    if (needBorder) {
      return interpolateLab(this.color, this.background)(0.83);
    }
    return fillColor;
  }

  resolveColor = c => {
    if (!c) {
      return c;
    }
    const col = String(c).toLowerCase();
    if (col === 'accentcolor') {
      return this.accentColor || baseTheme.accentColor;
    }
    if (col === 'textcolor') {
      return this.color;
    }
    if (col === 'backgroundcolor') {
      return this.background;
    }
    return col;
  };

  saveData () {
    const data = {};
    if (this.accentColor) {
      data.accentColor = this.accentColor;
    }
    if (this.backgroundColor) {
      data.backgroundColor = this.backgroundColor;
    }
    if (this.titleFont) {
      data.titleFont = this.titleFont;
    }
    if (this.chartFont) {
      data.chartFont = this.chartFont;
    }
    if (this.bodyFont) {
      data.bodyFont = this.bodyFont;
    }
    return data;
  }

  /**
   * Emit style declarations for styles that are different from a current theme.
   * As with .styles, `backgroundColor`, `color` and `fontFamily` are always emitted.
   * @param {Theme} [currentTheme] The current theme, if any
   * @return {Record<string, any>}
   */
  subStyles (currentTheme) {
    if (!currentTheme) {
      return this.styles;
    }
    const s = {
      backgroundColor: this.backgroundColor,
      color: this.color,
      fontFamily: this.bodyFontStack,
    };
    for (const [ key, parentValue ] of Object.entries(currentTheme.vars)) {
      const childValue = this.vars[key];
      if (String(parentValue) !== String(childValue)) {
        s[key] = childValue;
      }
    }
    return s;
  }

  get styles () {
    return {
      // XXX: examine stopping the applicaton of CSS styles here, and having
      //      using points do this explicitly with `background: var(--background)`
      backgroundColor: this.backgroundColorOverride || this.backgroundColor,
      color: this.color,
      fontFamily: this.bodyFontStack,
      ...this.vars,
    };
  }
}

export const defaultTheme = new Theme();
