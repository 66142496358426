// @ts-nocheck
/* eslint-disable */
// WARNING: this is a generated file -- do not edit!

// Names of font-types
const types = [
  "sans-serif",
  "cursive",
  "monospace",
  "serif",
  "fantasy"
];

// Names of supported fonts
const fonts = [
  "Abadi MT Condensed Light",
  "Arial",
  "Amatic SC",
  "Chalkboard",
  "Andale Mono",
  "Lucida Console",
  "Andale Mono WT",
  "AppleGothic",
  "Century Gothic",
  "Apple Gothic",
  "Helvetica Neue",
  "Arial Nova",
  "Arial Black",
  "Arial Narrow",
  "Arial Rounded MT Bold",
  "Arvo",
  "Roboto Slab",
  "Avant Garde",
  "Avantgarde",
  "Avenir",
  "Museo Sans",
  "Avenir Next",
  "Bahnschrift",
  "DIN 1451",
  "Baskerville",
  "Big Caslon",
  "Times New Roman",
  "Bitstream Vera Sans Mono",
  "Consolas",
  "Bodoni Moda",
  "Bodoni MT",
  "Didot",
  "Bodoni",
  "Book Antiqua",
  "Palatino",
  "Bradley Hand ITC",
  "Tempus Sans ITC",
  "Brush Script",
  "Brush Script MT",
  "Cabin",
  "Tahoma",
  "Caladea",
  "Hoefler Text",
  "Calibri",
  "Carlito",
  "Calibri Light",
  "Carlito Light",
  "Calisto MT",
  "Cambria",
  "Candara",
  "Segoe",
  "Caveat",
  "CenturyGothic",
  "Comic Sans MS",
  "Charcoal",
  "Helvetica Inserat",
  "Chivo",
  "Helvetica",
  "Cochin",
  "Comfortaa",
  "Futura",
  "Comic Sans Pro",
  "monaco",
  "Constantia",
  "Lucida Bright",
  "Copperplate",
  "Copperplate Gothic Light",
  "Copperplate Gothic Bold",
  "Corbel",
  "Lucida Grande",
  "Corsiva",
  "Lucida Handwriting",
  "Courier",
  "Liberation Mono",
  "Courier New",
  "Courier Prime",
  "Dejavu Sans",
  "DejaVu Sans Condensed",
  "Verdana",
  "DejaVu Sans Mono",
  "Didot LT STD",
  "DIN Alternate",
  "DM Sans",
  "BlinkMacSystemFont",
  "Domine",
  "Droid Sans",
  "Myriad",
  "Droid Serif",
  "Liberation Serif",
  "Fira Sans",
  "Franklin Gothic",
  "Franklin Gothic Medium",
  "ITC Franklin Gothic",
  "Frutiger",
  "Frutiger Linotype",
  "Trebuchet MS",
  "Gabriola",
  "Garamond",
  "EB Garamond",
  "Geneva",
  "Georgia",
  "Georgia Pro",
  "Gill Sans",
  "Gill Sans MT",
  "Gill Sans Nova",
  "Goudy Old Style",
  "Haettenschweiler",
  "Liberation Sans",
  "Impact",
  "Inter",
  "helvetica",
  "Josefin Sans",
  "futura",
  "Libre Baskerville",
  "Libre Franklin",
  "Lobster",
  "Lora",
  "Lucida serif",
  "Lucidabright",
  "Lucida Sans Unicode",
  "Monotype Corsiva",
  "Lucida Sans",
  "Lucida Sans Typewriter",
  "Merriweather",
  "Microsoft Sans Serif",
  "Monaco",
  "Montserrat",
  "Roboto Sans",
  "Myriad Pro",
  "Neue Haas Grotesk Text Pro",
  "News Gothic MT",
  "Nunito",
  "Roboto",
  "Optima",
  "Oswald",
  "Open Sans",
  "Segoe UI",
  "Open Sans Medium",
  "Overpass",
  "Pacifico",
  "Palatino Linotype",
  "Papyrus",
  "Perpetua",
  "Playfair Display",
  "Poppins",
  "Proxima Nova",
  "Raleway",
  "Roboto Mono",
  "Rockwell Nova",
  "Rockwell",
  "Rubik",
  "Source Sans Pro",
  "Source Serif Pro",
  "Space Mono",
  "Spectral",
  "Symbol",
  "Syncopate",
  "Times",
  "Ubuntu",
  "Verdana Pro",
  "Westminster",
  "OCR-A",
  "Work Sans"
];

// Substitution table
const fontlist = ([[0,1,0],[2,3,1],[4,5,2,6],[6,5,2,4],[7,8,0,9],[9,8,0,7],[1,10,0,11],[11,10,0,1],[12,1,0],[13,1,0],[14,1,0],[15,16,0],[17,8,0,18],[18,8,0,17],[19,20,0,21],[21,20,0,19],[22,23,0],[24,25,3],[25,26,3],[27,28,2],[29,30,3],[30,31,3,32],[32,31,3,30],[33,34,3],[35,36,1],[37,null,1,38],[38,null,1,37],[39,40,0],[41,42,3],[43,44,0],[45,46,0],[47,34,3],[48,41,3],[49,50,0],[44,49,0],[51,3,1],[8,7,0,52],[52,7,0,8],[3,53,1],[54,55,0],[56,57,0],[58,24,3],[59,60,0],[53,3,1,61],[61,3,1,53],[28,62,2],[63,64,3],[65,66,4],[67,65,4],[66,65,4],[68,69,0],[70,71,1],[72,73,2,74],[74,73,2,72],[75,72,2],[76,10,0],[77,78,0],[79,27,2],[31,42,3,80],[80,42,3,31],[23,22,0,81],[81,22,0,23],[82,83,0],[84,47,3],[85,86,0],[87,88,3],[89,57,0],[90,9,0,91,92],[91,9,0,90,92],[92,9,0,90,91],[93,76,0,94],[94,76,0,93],[60,95,0],[96,71,1],[97,33,3,98],[98,33,3,97],[99,40,0],[100,26,3,101],[101,26,3,100],[102,43,0,103,104],[103,43,0,102,104],[104,43,0,102,103],[105,97,3],[106,54,0],[57,107,0],[55,12,0],[10,57,0],[42,88,3],[108,106,0],[109,110,0],[111,112,0],[73,62,2],[107,1,0],[88,26,0],[113,24,3],[114,90,0],[115,37,1],[116,34,3],[64,117,3,118],[118,117,3,64],[5,27,2],[69,119,0],[71,120,1],[121,99,0],[122,5,2],[119,121,0],[123,34,3],[124,10,0],[125,5,2],[120,35,1],[126,127,0],[20,8,0],[86,93,0,128],[128,93,0,86],[129,10,0],[130,90,0],[131,132,0],[133,50,0],[134,0,0],[135,136,0],[137,136,0],[138,57,0],[139,null,1],[34,140,3],[140,97,3],[141,null,4],[142,24,3],[143,30,3],[144,69,0],[145,126,0],[146,1,0],[132,57,0,127],[127,57,0,132],[147,28,2],[16,148,0],[148,57,0,149],[149,57,0,148],[150,1,0],[136,93,0,50],[50,93,0,136],[151,132,0],[152,100,3],[153,72,2],[154,26,0],[155,26,3],[156,60,0],[40,77,0],[36,71,1],[157,26,0],[26,88,3,157],[157,88,3,26],[95,69,0],[158,43,0],[78,99,0],[159,99,0],[160,161,2],[162,69,0],[83,132,0]])
  .map(row => {
    return ({
      name: fonts[row[0]],
      next: fonts[row[1]] || null,
      type: types[row[2]],
      alt: row.slice(3).map(d => fonts[d]),
    });
  });

export default fontlist;
