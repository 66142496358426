import { ciede2000factory } from './src/ciede2000';

export {
  contrast,
  getHexValue,
  hexRegex,
  hexRegexQuotes,
  isSameColor,
  isValidColor,
  luminosity,
} from './src/colors';

export const ciede2000 = ciede2000factory();
export const ciede2000Weighted = ciede2000factory;
