import fontlist from './fontsub';

/** @type {Map<string, string>} */
const cache = new Map();

/**
 * @param {string | undefined} [fontfam]
 */
export function getFontStack (fontfam, defaultType = 'sans-serif') {
  if (!fontfam) {
    return defaultType;
  }
  const cached = cache.get(fontfam);
  if (cached) {
    return cached;
  }
  const fontStack = _getFontStack(fontfam);
  cache.set(fontfam, fontStack);
  return fontStack;
}

/**
 * @param {string} fontfam
 */
function _getFontStack (fontfam) {
  // This is mostly to counter the "Calibri (Body)" case
  fontfam = fontfam.replace(/\(\w+\)/i, '').trim();
  let type = '';
  const fontStack = [];
  const nextFont = name => {
    const currFont = fontlist.find(d => d.name.toLowerCase() === name.toLowerCase());
    if (currFont) {
      if (!type) {
        type = currFont.type;
      }
      fontStack.push(currFont.name);
      fontStack.push(...currFont.alt);
      if (currFont.next && !fontStack.includes(currFont.next)) {
        nextFont(currFont.next);
      }
    }
  };
  nextFont(fontfam);
  if (type) {
    fontStack.push(type);
  }
  if (!fontStack.length) {
    fontStack.push(fontfam);
  }
  return fontStack.join(', ');
}
