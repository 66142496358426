import { interpolateLab } from 'd3-interpolate';

import { defaultPalette, gridChangePal1, gridChangePal2, gridPal1, gridPal2 } from '@/grid/chart/utils/color';
import deepFreeze from '@/grid/utils/deepFreeze';
import { getFontStack } from '@/utils/fontstack';

export const DEFAULT_CHART_FONT = 'Nunito';

// this is a full list of all styles used by the axes
export const AXIS_DEFAULTS = {
  color: 'black',
  fontFamily: DEFAULT_CHART_FONT,
  fontSize: 15,
  lineHeight: 1.2,
  gutterSize: 5,
  axisLine: false,
  ticks: 'none', // outside | inside | across | none
  tickSize: 6,
  domainTicks: false,
  titleFontSize: 11,
  titlePosition: 'center', // corner | center
};

// this is a full list of all styles used by the legend
export const LEGEND_DEFAULTS = {
  margin: 7,
  fontSize: 15,
  color: 'black',
  fontFamily: DEFAULT_CHART_FONT,
  symbol: 'square', // square | circle | line
  fontStack: '',
};

// List of styles used by chart footnotes.
export const FOOTNOTE_DEFAULTS = {
  color: 'black',
  fontFamily: DEFAULT_CHART_FONT,
  fontSize: 14,
  lineHeight: 1.2,
  margin: 2,
};

// List of styles used by chart annotations.
export const ANNOTATION_DEFAULTS = {
  fontFamily:  DEFAULT_CHART_FONT,
  fontSize: 14,
  label: {
    color: 'currentColor',
    opacity: 1,
    background: 'white',
    backgroundOpacity: 0.6,
  },
  line: {
    color: 'currentColor',
    opacity: 0.6,
    dashArray: '4 4',
  },
  point: {
    color: 'currentColor',
    opacity: 0.35,
  },
  bar: {
    background: 'white',
    backgroundOpacity: 0.6,
    color: 'currentColor',
    dashArray: '4 4',
    opacity: 1,
  },
};

// this is a full list of all styles used by the grid
export const GRID_DEFAULTS = {
  color: 'silver',
  dashArray: null,
  zeroColor: 'gray',
  zeroDashArray: null,
};

// this is a full list of all styles used by the charts
// it is used as a blueprint for constructing a style object
export const BASE_STYLES = {
  palette: defaultPalette,
  changePalette: gridChangePal2,
  dataAxis: { ...AXIS_DEFAULTS },
  valueAxis: { ...AXIS_DEFAULTS },
  grid: { ...GRID_DEFAULTS },
  legend: { ...LEGEND_DEFAULTS },
  annotation: { ...ANNOTATION_DEFAULTS },
  footnote: { ...FOOTNOTE_DEFAULTS },
};

function gridClassicStyle (fg, bg, font) {
  const blend = interpolateLab(fg, bg);
  const ax = {
    fontFamily: font,
    fontSize: 14,
    color: blend(0),
    domainTicks: true,
    ticks: 'outside',
    axisLine: true,
    titleFontSize: 12,
    titlePosition: 'corner',
    gutterSize: 3,
  };
  return {
    palette: gridPal1,
    changePalette: gridChangePal1,
    dataAxis: {
      ...ax,
      ticks: 'none',
      domainTicks: false,
      axisLine: false,
    },
    valueAxis: { ...ax },
    grid: {
      color: blend(0.8),
      dashArray: null,
      zeroColor: blend(0.5),
      zeroDashArray: '',
    },
    legend: {
      margin: 7,
      fontFamily: font,
      fontSize: 14,
      color: blend(0),
      symbol: 'square',
    },
    annotation: {
      fontSize: 14,
      label: {
        color: blend(0.2),
        background: bg,
      },
      line: {
        color: blend(0.35),
        opacity: 1,
        dashArray: '2 2',
      },
      point: {
        color: blend(0.35),
      },
      bar: {
        background: bg,
        color: blend(0.35),
        dashArray: '4 4',
      },
    },
    footnote: {
      color: blend(0),
    },
  };
}

// we need to be careful here: colors need to blended with background
function gridModernStyle (fg, bg, font) {
  const blend = interpolateLab(fg, bg);
  const ax = {
    fontFamily: font,
    fontSize: 15,
    domainTicks: false,
    color: blend(0.39), // ash
    axisLine: false,
    titleFontSize: 11,
    titlePosition: 'center',
    ticks: 'none',
  };
  return {
    palette: gridPal2,
    changePalette: gridChangePal2,
    dataAxis: { ...ax },
    valueAxis: { ...ax },
    grid: {
      color: blend(0.84), // concrete
      dashArray: '4 4',
      zeroColor: blend(0.515),
      zeroDashArray: null,
    },
    legend: {
      margin: 6,
      fontFamily: font,
      fontSize: 15,
      color: blend(0),
      symbol: 'circle',
    },
    annotation: {
      label: {
        color: blend(0.39),
        background: bg,
      },
      line: {
        color: blend(0.50),
        opacity: 1,
      },
      point: {
        color: blend(0.50),
      },
      bar: {
        background: bg,
        color: blend(0.50),
      },
    },
    footnote: {
      color: blend(0.39), // ash
    },
  };
}

// only copy over properties that exist on the recessive (blueprint copy)
// but always prefer things that come from the dominant side if they exist
function mergeStyles (recessive, dominant) {
  const ret = Array.isArray(recessive) ? [] : {};
  const keys = Object.getOwnPropertyNames(recessive);
  for (const key of keys) {
    const r_value = recessive[key];
    let d_value = dominant[key];
    if (r_value && typeof r_value === 'object') {
      if (!d_value || typeof d_value !== 'object') {
        // if themeStyles is not an object, we use a blank
        d_value = {};
      }
      ret[key] = mergeStyles(r_value, d_value);
    }
    else if (Object.prototype.hasOwnProperty.call(dominant, key)) {
      ret[key] = d_value;
    }
    else {
      ret[key] = r_value;
    }
  }
  return ret;
}

export default class ChartTheme {
  constructor (name, {
    foreground = 'black',
    background = 'white',
    font = DEFAULT_CHART_FONT,
  } = {}) {
    this.name = name;
    this.background = background;
    this.color = foreground;
    this.fontFamily = font || DEFAULT_CHART_FONT;
    this.legend = { ...LEGEND_DEFAULTS };
    this.footnote = { ...FOOTNOTE_DEFAULTS };
    this.dataAxis = {};
    this.valueAxis = {};
    /** @type {string[]} */
    this.palette; // initialized with Object.assign below
    /** @type {string[]} */
    this.changePalette;

    let themeStyles = {};
    if (name === ChartTheme.gridChartsOld) {
      themeStyles = gridClassicStyle(foreground, background, this.fontFamily);
    }
    else { // ChartTheme.gridChartsNew
      themeStyles = gridModernStyle(foreground, background, this.fontFamily);
    }
    Object.assign(this, mergeStyles(BASE_STYLES, themeStyles));

    this.fontStack = getFontStack(this.fontFamily);
    this.legend.fontStack = getFontStack(this.legend.fontFamily || this.fontFamily);
    this.dataAxis.fontStack = getFontStack(this.dataAxis.fontFamily || this.fontFamily);
    this.valueAxis.fontStack = getFontStack(this.valueAxis.fontFamily || this.fontFamily);

    // immutable but allows subclassing
    if (this.constructor === ChartTheme) {
      deepFreeze(this);
    }
  }
}

ChartTheme.gridChartsOld = 'grid-v1';
ChartTheme.gridChartsNew = 'grid-v2';
ChartTheme.defaultName = ChartTheme.gridChartsNew;
