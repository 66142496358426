import { rgb } from 'd3-color';

/**
 * Doubles the size of the colour palette by duplicating the existing colours and mixing them with a
 * given amount of white.
 */
export function extendPal (palette, alpha) {
  const w = rgb('#fff');
  return palette
    .map(c => rgb(c))
    .map(c => rgb(
      c.r + (w.r - c.r) * alpha,
      c.g + (w.g - c.g) * alpha,
      c.b + (w.b - c.b) * alpha,
    ).hex());
}

export const gridPal1 = [ '#4fa3fc', '#ff9729', '#ffd738', '#7aaf08', '#c3282b', '#969696', '#6c0df2' ];
export const gridChangePal1 = [ '#4fa3fc', '#ff9729', '#969696' ];

export const gridPal2 = [ '#7a33ff', '#fad75a', '#33ccff', '#f0725b', '#2779f2', '#f261a8', '#89e291' ];
export const gridChangePal2 = [ '#89e291', '#f0725b', '#7a33ff' ];

export const defaultPalette = gridPal2;

export const customChangeColors = [
  {
    label: 'Not set',
    value: '',
  },
  {
    label: 'Tropical',
    value: '={"#89e291","#f0725b","#7a33ff"}',
  },
  {
    label: 'Mediterranean',
    value: '={"#4fa3fc","#ff9729","#969696"}',
  },
  {
    label: 'Monochrome',
    value: 'textColor',
  },
  {
    label: 'Inverted default',
    value: '={"#DD3314","#208428","#969696"}',
  },
];

export const customChartColors = [
  {
    label: 'Not set',
    value: '',
  },
  // GRID's default palette by Tony Wang
  {
    label: 'Tropical',
    value: '={"#7a33ff","#fad75a","#33ccff","#f0725b","#2779f2","#f261a8","#89e291"}',
  },
  // GRID's classic palette by Þorlákur Lúðvíksson
  {
    label: 'Mediterranean',
    value: '={"#4fa3fc","#ff9729","#ffd738","#7aaf08","#c3282b","#969696","#6c0df2"}',
  },
  // Palette by Masataka Okabe and Kei Ito
  // From Color Universal Design (CUD): https://jfly.uni-koeln.de/color/
  {
    label: 'Color Universal Design',
    value: '={"#000000","#e69f00","#56b4e9","#009e73","#f0e442","#0072b2","#d55e00","#cc79a7"}',
  },
  // Palette by Stephen Few
  // http://www.mulinblog.com/a-color-palette-optimized-for-data-visualization/
  {
    label: 'Show me the numbers',
    value: '={"#4D4D4D","#5DA5DA","#FAA43A","#60BD68","#F17CB0","#B2912F","#B276B2","#DECF3F","#F15854"}',
  },
  // Palette by Paul Tol
  // https://personal.sron.nl/~pault/#sec:qualitative
  {
    label: 'Tol light',
    value: '={"#77AADD","#EE8866","#EEDD88","#FFAABB","#99DDFF","#44BB99","#BBCC33","#AAAA00","#DDDDDD"}',
  },
  // Palette by Paul Tol
  // https://personal.sron.nl/~pault/#sec:qualitative
  {
    label: 'Tol muted',
    value: '={"#CC6677","#332288","#DDCC77","#117733","#88CCEE","#882255","#44AA99","#999933","#AA4499","#DDDDDD"}',
  },
  // see conversation for commented out palettes issue https://github.com/GRID-is/GRID-client/issues/2481;
  // Palette by Google (from Sheets [trimmed])
  // https://stackoverflow.com/a/56495465/27388
  // {
  //   label: 'Mountain View',
  //   value: '={"#3366CC","#DC3912","#FF9900","#109618","#990099","#3B3EAC","#0099C6","#DD4477"}'
  // },
  // Palette by Microsoft (from Excel)
  // {
  //   label: 'Redmond',
  //   value: '={"#4472C4","#ED7D31","#A5A5A5","#FFC000","#5B9BD5","#70AD47","#0563C1","#954F72"}'
  // },
];
