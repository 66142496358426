import { color as d3_color } from 'd3-color';

// convert an RGB color value to an sRGB value
function sRGB (v) {
  const c = v / 255;
  return (c <= 0.03928)
    ? c / 12.92
    : ((c + 0.055) / 1.055) ** 2.4;
}

export function luminosity (colour, includeOpacity = true) {
  const { r, g, b, opacity } = d3_color(colour).rgb();
  if (isNaN(r * g * b)) {
    return 1;
  }
  const l = sRGB(r) * 0.2126 + sRGB(g) * 0.7152 + sRGB(b) * 0.0722;
  return l * (includeOpacity ? opacity : 1);
}

export function contrast (colour, light = 'white', dark = 'black') {
  // get the luminosity of all 3 colors
  const l1 = luminosity(colour);
  const l2 = luminosity(light);
  const l3 = luminosity(dark);
  // compute contrast ratios for color against light or dark
  const ratio_l12 = (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
  const ratio_l13 = (Math.max(l1, l3) + 0.05) / (Math.min(l1, l3) + 0.05);
  // pick whichever color has the higher contrast
  return (ratio_l12 > ratio_l13) ? light : dark;
}

export const isSameColor = (a, b) => {
  return String(d3_color(a)) === String(d3_color(b));
};

/**
 * Converts a CSS colour to a hex code. If `value` isn't a valid colour, returns the provided
 * fallback colour. Note that this is left untouched, so if you provide an invalid fallback colour
 * then that's what you'll get back.
 */
export function getHexValue (value: string, fallbackColor: string | null = null): string | null {
  const valueColor = d3_color(value);
  return valueColor ? valueColor.formatHex() : fallbackColor;
}

export function isValidColor (value: string): boolean {
  // allow: #000, #000000
  if (/^#(?:[a-f\d]{3}|[a-f\d]{6})$/i.test(value)) {
    return true;
  }
  // allow named colors: mistyrose, rosybrown, lemonchiffon,...
  if (/^(?:[a-z]{3,})$/i.test(value) && d3_color(value)) {
    return true;
  }
  return false;
}

export const hexRegex = /(#[a-f0-9]{6})|(#[a-f0-9]{3})/ig;
export const hexRegexQuotes = /"(#[a-f0-9]{6})"|"(#[a-f0-9]{3})"/ig;
