import { lab } from 'd3-color';

const { PI: π, sqrt, abs, cos, sin, exp } = Math;

const x7 = (a, b) => sqrt((a ** 7) / ((b ** 7) + (25 ** 7)));

/*
  CIEDE2000 color difference, original Matlab implementation by Gaurav Sharma
  http://www2.ece.rochester.edu/~gsharma/ciede2000/
*/
export function ciede2000factory (kL = 1, kC = 1, kH = 1) {
  return function (std, smp) {
    const { l: lStd, a: aStd, b: bStd } = lab(std);
    const cStd = sqrt(aStd * aStd + bStd * bStd);

    const { l: lSmp, a: aSmp, b: bSmp } = lab(smp);
    const cSmp = sqrt(aSmp * aSmp + bSmp * bSmp);

    const cAvg = (cStd + cSmp) / 2;
    const G = 0.5 * (1 - x7(cAvg, cAvg));
    const apStd = aStd * (1 + G);
    const apSmp = aSmp * (1 + G);
    const cpStd = sqrt(apStd * apStd + bStd * bStd);
    const cpSmp = sqrt(apSmp * apSmp + bSmp * bSmp);

    let hpStd = abs(apStd) + abs(bStd) ? Math.atan2(bStd, apStd) : 0;
    hpStd += (hpStd < 0) ? 2 * π : 0;

    let hpSmp = abs(apSmp) + abs(bSmp) ? Math.atan2(bSmp, apSmp) : 0;
    hpSmp += (hpSmp < 0) ? 2 * π : 0;

    const dL = lSmp - lStd;
    const dC = cpSmp - cpStd;

    let dhp = cpStd * cpSmp === 0 ? 0 : hpSmp - hpStd;
    dhp -= (dhp > π) ? 2 * π : 0;
    dhp += (dhp < -π) ? 2 * π : 0;

    const dH = 2 * sqrt(cpStd * cpSmp) * sin(dhp / 2);
    const Lp = (lStd + lSmp) / 2;
    const Cp = (cpStd + cpSmp) / 2;

    let hp;
    if (cpStd * cpSmp === 0) {
      hp = hpStd + hpSmp;
    }
    else {
      hp = (hpStd + hpSmp) / 2;
      hp -= (abs(hpStd - hpSmp) > π) ? π : 0;
      hp += (hp < 0) ? 2 * π : 0;
    }

    const Lpm50 = (Lp - 50) ** 2;
    const T = 1 -
        0.17 * cos(hp - π / 6) +
        0.24 * cos(2 * hp) +
        0.32 * cos(3 * hp + π / 30) -
        0.20 * cos(4 * hp - 63 * π / 180);

    const Sl = 1 + (0.015 * Lpm50) / sqrt(20 + Lpm50);
    const Sc = 1 + 0.045 * Cp;
    const Sh = 1 + 0.015 * Cp * T;
    const deltaTheta = 30 * π / 180 * exp(-1 * (((180 / π * hp - 275) / 25) ** 2));
    const Rc = 2 * x7(Cp, Cp);
    const Rt = -1 * sin(2 * deltaTheta) * Rc;

    return sqrt((
      ((dL / (kL * Sl)) ** 2) +
      ((dC / (kC * Sc)) ** 2) +
      ((dH / (kH * Sh)) ** 2) +
      Rt * dC / (kC * Sc) * dH / (kH * Sh)
    ));
  };
}
